import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import '../styles/student-work-single.css'
import {Link} from 'gatsby'
import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox";
import ReactPlayer from 'react-player'

function StudentWorkSingle (props) {
  const {_rawDescription, webLink, course, country, title, name, images, year, pdf, video} = props
  //console.log(images)

  const options = {
    thumbnails: {
      showThumbnails: false
    },
    buttons: {
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false
    }
  };

  if(images.length > 1) {
    options.buttons.showNextButton = true
    options.buttons.showPrevButton = true
  }

  return (
    <Container>
    <SimpleReactLightbox>
      <div className="wrapper">
        <SRLWrapper options={options}>
        <h3><Link to="/student-work/">Student work</Link></h3>
        <div id="student-work-single" className="student-work-single">

            <h2>{title}</h2>

            <div className="grid">

              <div className="width-12-12-m width-6-12">

              {images && images[0].asset && (
                <div className="image-holder">
                  <a data-attribute="SRL" href={imageUrlFor(buildImageObj(images[0]))
                          .width(2000)
                          .url()}
                    >
                  <figure
                    style={{
                      backgroundImage: `url(${images[0].asset.metadata.lqip})`,
                      paddingTop: `calc(100% / ${images[0].asset.metadata.dimensions.aspectRatio})`
                     }}
                  >
                    <img
                      src={imageUrlFor(buildImageObj(images[0]))
                        .width(1200)
                        .auto('format')
                        .url()}
                      alt={images[0].alt}
                      title={images[0].alt}
                    />
                  </figure>
                  </a>
                  {images[0].caption && (
                    <span className="caption">
                      {images[0].caption}
                    </span>
                  )}

                </div>
              )}

              </div>


              <div className="width-12-12-m width-6-12">

                <div className="info">

                  {name && 
                        <h3>{name}</h3>
                  }

                  {course && year && country &&
                        <p>{course} | {year} | {country}</p>
                  }

                  {pdf && pdf.file && 
                        <p><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
                    }

                  {webLink && 
                    <p className="extras"><a href={webLink} rel="noopener noreferrer" target="_blank">View Work</a></p>
                  }

                </div>


                {_rawDescription && (
                  <div className="body-holder">
                    {_rawDescription && <PortableText blocks={_rawDescription} />}
                  </div>
                )}

                  <div className="grid">

                  {images && images.slice(1).map(image => {

                    return image.asset && (

                    <div className="gallery-image width-6-12-m width-6-12">
                    <a data-attribute="SRL" href={imageUrlFor(buildImageObj(image))
                          .width(2000)
                          .url()}
                    >
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .width(800)
                          .height(800)
                          .fit('crop')
                          .url()}
                        alt={image.alt}
                      />
                      </a>
                    </div>
                  )
                  })}

                  </div>

                  {video && video.url && (
                    <div className="video-holder">
                      <div className='player-wrapper'>
                        <ReactPlayer 
                          className='react-player'
                          url={video.url}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </div>
                  )}

                  {props.embed && 
                    <div className="embed">
                    <iframe src={props.embed} 
                            title="artwork embed"
                            width="100%" 
                            height="480"
                            seamless="seamless" 
                            scrolling="no" 
                            frameBorder="0" 
                            allowFullScreen>
                    </iframe>
                    </div>
                  }

          </div>

          </div>

        </div>
        </SRLWrapper>
      </div>
    </SimpleReactLightbox>
    </Container>
  )
}

export default StudentWorkSingle