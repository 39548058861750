import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import StudentWorkSingle from '../components/student-work-single'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'

export const query = graphql`
  query StudentWorkTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    studentWork: sanityStudentWork(id: {eq: $id}) {
      id
      images {
        ...SanityImage
        alt
        caption
      }
      title
      name
      course
      country
      year
      embed
      pdf {
        title
        file {
          asset {
            url
          }
        }
      }
      webLink
      video {
        url
      }
      slug {
        current
      }
      _rawDescription(resolveReferences: {maxDepth: 5})
    }
  }
`

const StudentWorkTemplate = props => {
  const {data, errors} = props

  const site = (data || {}).site

  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const studentWork = data && data.studentWork

  //console.log('font');
  //console.log(font);

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      {errors && <SEO title='GraphQL Error' />}
      {studentWork && <SEO title={studentWork.title || 'Untitled'} author={site.author} description={toPlainText(studentWork._rawDescription)} image={studentWork.images[0]} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {studentWork && 
        <StudentWorkSingle {...studentWork} />
      }
    </Layout>
  )
}

export default StudentWorkTemplate